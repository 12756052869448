html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

img {
    width: 350px;
    top: 8px;
    left: 16px;
    /* float: left; */
}

body {
    color: #343434;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    line-height: 1.5;

}

a {
    color: black;
}

a:hover {
    text-decoration: none;;
}

header {
    /*background: oldlace;*/
    background: rgba(97, 218, 251, 0.0);
    color: black;


}

h1 {
    font-size: 100px;
    line-height: 1;;
}

h2 {
    font-size: 45px;
}

h1, h2 {
    font-family: 'Caveat', cursive;
    font-weight: 400;
    margin: 0;
}

.content-wrap {
    max-width: 800px;
    width: 85%;
    margin: 0 auto;
    /*padding: 20px 0;*/
    padding: 20px 10px; /* Adjust the left and right padding */
}


h3 {
    /*padding-top:10px ;*/
    margin-bottom: 0;
}

h5 {
    padding-top: 10px;
    margin-bottom: 0;
}

.item-details h3 + p {
    font-style: italic;
}

.item-details h3 ~ p {
    margin: 0;
}

.projects {
    background: rgba(97, 218, 251, 0.1);
    /*background:ghostwhite ;*/
}


.projects a {
    color: darkblue;

}

.projects .btn {
    background: #F7FFF7;
    background: #2F3061;
    text-decoration: none;
    padding: 8px;
    border-radius: 4px;
    display: inline-block;

}

.projects .btn:hover {
    background: rgba(47, 48, 97, 80%);;
}


.work-experience {
    background: ghostwhite;
}

.education {
    background-image: url("./placeholder_images/joanna-kosinska-unsplash.jpg");
    background-size: cover;
    background-position: top right;
    /*padding-bottom: 100px;*/
}

.education p {
    width: 60%;
}

footer {
    background: whitesmoke;
    color: black;

}

.contact-list {
    list-style-type: none;

    padding: 0;

}

.contact-list a {
    padding: 15px;
    display: inline-block;
}

/*Responsive--------------------------------*/

@media screen and (min-width: 750px) {

    header, footer {
        text-align: center;
    }

    .job-item {
        display: grid;
        grid-template-columns: 1fr 2fr;
        column-gap: 20px;
    }

    .contact-list {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 749px) {

    h1 {
        font-size: 75px;
        line-height: 0.9;
        margin-bottom: 20px;
    }


    h2 {
        line-height: 1;
    }

    .contact-list a {
        padding: 5px;
    }
}

